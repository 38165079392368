<template>
  <v-dialog 
    :value="isOpen" 
    :content-class="isClassAdd ? contentClass : 'custom-popup'"
    :width="width"
    @click:outside="$emit('change', false)"
    :hide-overlay="hideOverlay"
    :attach="attach ? attach : false"

  >
    <v-icon
      @click="$emit('change', false)"
      class="custom-popup__close-btn mb-3"
      size="18"
      color="#FFFFFF"
      >$vuetify.icons.plusRotate</v-icon
    >
    <slot> </slot>
  </v-dialog>
</template>

<script>
export default {
  model: {
    prop: "isOpen",
    event: "change",
  },

  props: {
    isOpen: Boolean,
    width: String,
    contentClass: String,
    hideOverlay: Boolean,
    attach: String
  },

  computed: {
    isClassAdd () {
      if (this.contentClass && this.contentClass.length) {
        return true
      } else return false
    }
  },

  watch: {
    isOpen (newVal) {
      if (newVal) {
        document.addEventListener('keydown', this.handleEsc);
      } else {
        document.removeEventListener('keydown', this.handleEsc);
      }
    }
  },

  methods: {
    handleEsc (event) {
      if (event.key === 'Escape') {
        event.preventDefault();
        this.$emit('change', false);
      }
    }
  },

  destroyed() {
    document.removeEventListener('keydown', this.handleEsc);
  }
};
</script>

<style lang="scss">
.custom-popup {
  position: relative;
  overflow-y: visible;
  &__close-btn {
    position: absolute !important;
    right: 0;
    top: -25px;
  }
}
.popup-attached-ovaerlay {
  z-index: inherit;
}
</style>
