var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-14"},[_c('div',{staticClass:"collections",attrs:{"data-qa":_vm.mode === 'render' ? 'collections-render' : 'collections-product'}},[(_vm.isSearchActive)?_c('div',{staticClass:"collections__header"},[_c('div',{staticClass:"pa-0 collections__title"},[_vm._v("Коллекции")]),_c('div',{staticClass:"collections__action d-flex"},[_c('TipsVue',{attrs:{"keyTips":'collections-search'}},[_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.isSearchActive = false}}},[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("$vuetify.icons.search")])],1)],1),_c('PermissionWrapperVue',{attrs:{"permissionCode":'create_collection',"textStyles":{ padding: '14px' },"iconStyles":{ top: '8px' }}},[_c('TipsVue',{attrs:{"keyTips":'collections-add'}},[_c('v-btn',{staticClass:"collections__action__add",attrs:{"icon":"","data-qa":_vm.mode === 'render'
                  ? 'main-menu-create-render'
                  : 'main-menu-create-collection',"color":"primary"},on:{"click":function($event){_vm.isCreateCollection = true}}},[_c('v-icon',[_vm._v("mdi-view-grid-plus")])],1)],1)],1)],1)]):_c('div',{staticClass:"collections__search",style:({
        border: _vm.collectionSearch && _vm.collectionSearch.length == 0 ? 0 : '',
      })},[_c('v-text-field',{staticClass:"collections__search-input",attrs:{"solo":"","flat":"","color":"primary","hide-details":"","background-color":"secondary","dense":"","menu-props":{ contentClass: 'collections__search-menu' },"placeholder":"Введите название коллекции","hide-no-data":"","autofocus":""},on:{"input":_vm.getValueSearch,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.searchCollections(true)}}},[_c('template',{slot:"append"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{attrs:{"color":"primary","size":"18"},on:{"click":_vm.closeSearchCollection}},[_vm._v(" $vuetify.icons.closeRounded ")])],1)])],2),(_vm.searchText)?_c('div',{staticClass:"result-search"},[(_vm.collectionSearch && _vm.collectionSearch.length == 0)?_c('div',{staticClass:"mt-3"},[_vm._v(" По запросу "),_c('span',{staticClass:"result-search-bold"},[_vm._v(_vm._s(_vm.searchValueForReplace))]),_vm._v(" ничего не найдено ")]):_c('div',{staticClass:"mt-3"},[_vm._v(" Результаты поиска "),_c('span',{staticClass:"result-search-bold"},[_vm._v(_vm._s(_vm.searchValueForReplace))])])]):_vm._e()],1),(_vm.collectionSearch && _vm.collectionSearch.length !== 0)?_c('div',{staticClass:"collections__subheader"},[_c('button',{staticClass:"collections__subheader-item",attrs:{"ripple":false,"disabled":_vm.selectedCollection.length === _vm.collectionSearch.length},on:{"click":_vm.selectAllCollection}},[_vm._v(" Выделить все ")]),_c('div',{staticClass:"collections__subheader-line"}),_c('button',{staticClass:"collections__subheader-item",attrs:{"disabled":!_vm.selectedCollection.length},on:{"click":function($event){_vm.selectedCollection = [];
          _vm.searchValueForReplace = '';}}},[_vm._v(" Снять выделение ")])]):_vm._e(),(_vm.loadingCollections)?_c('div',{staticClass:"d-flex justify-center align-center pt-16"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_c('v-list',{staticClass:"pt-0 collections__list"},[(_vm.collectionSearch && _vm.collectionSearch.length == 0)?_c('div'):_c('v-list-item-group',{attrs:{"value":_vm.selectedCollection,"color":"primary"}},_vm._l((_vm.allCollections),function(collection,index){return _c('v-hover',{key:index,scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-list-item',{staticClass:"collection-list-item",class:{
              'collection-list-item__active': _vm.selectedCollection.includes(
                collection.key
              ),
            },attrs:{"active-class":"collection-list-item__active","value":collection.key},on:{"click":function($event){return _vm.toggleCollectionByList(collection.key)}}},[_c('div',{staticClass:"collection-list-item__content d-flex align-center"},[_c('div',{staticClass:"checkbox-wrapper"},[_c('v-checkbox',{staticClass:"mt-0 pt-0",attrs:{"hide-details":"","value":collection.key,"on-icon":"$vuetify.icons.trueCheckBox","off-icon":"$vuetify.icons.falseCheckBox","ripple":false},on:{"click":function($event){$event.stopPropagation();return (() => {}).apply(null, arguments)}},model:{value:(_vm.selectedCollection),callback:function ($$v) {_vm.selectedCollection=$$v},expression:"selectedCollection"}})],1),_c('p',{staticClass:"text-truncate",staticStyle:{"flex-grow":"2","width":"160px"}},[_vm._v(" "+_vm._s(collection.name)+" ")]),(_vm.mode !== 'render' && _vm.mode !== 'ai-photo')?_c('v-menu',{attrs:{"rounded":"zarbo","left":"","content-class":"rounded-lg elevation-0 collections__menu"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',{staticClass:"collections__choise__append-wrapper"},[_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}],staticClass:"collections__choise__append",attrs:{"icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-menu")])],1)],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',[_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('PermissionWrapperVue',{attrs:{"billingValue":_vm.mode === 'render' ? '' : _vm.productsCount,"permissionCode":_vm.mode === 'render'
                            ? 'create_render360'
                            : 'create_product',"billingCode":_vm.mode === 'render' ? 'render360' : 'product_count'}},[_c('v-list-item',{attrs:{"data-qa":_vm.mode === 'render'
                              ? 'create-render'
                              : 'create-product',"disabled":_vm.selectedCollection === null},on:{"click":function($event){return _vm.createProductFromCollection(collection)}}},[_c('v-list-item-icon',{staticClass:"mr-3 my-auto"},[_c('v-icon',{attrs:{"color":hover ? 'primary' : '',"size":"18"}},[_vm._v("$vuetify.icons.createProduct")])],1),_c('v-list-item-title',{staticClass:"pl-1"},[_vm._v("Создать продукт")])],1)],1)]}}],null,true)}),_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('PermissionWrapperVue',{attrs:{"permissionCode":_vm.mode === 'render'
                            ? 'create_render360'
                            : 'update_collection'}},[_c('v-list-item',{on:{"click":function($event){return _vm.beforeEditCollection(collection)}}},[_c('v-list-item-icon',{staticClass:"mr-3 my-auto"},[_c('v-icon',{attrs:{"color":hover ? 'primary' : '',"size":"18"}},[_vm._v("$vuetify.icons.edit")])],1),_c('v-list-item-title',{staticClass:"pl-1",attrs:{"data-qa":_vm.mode === 'render'
                                ? 'rename-collection-render'
                                : 'rename-collection-product'}},[_vm._v("Переименовать")])],1)],1)]}}],null,true)}),_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('PermissionWrapperVue',{attrs:{"permissionCode":_vm.mode === 'render'
                            ? 'create_render360'
                            : 'delete_collection'}},[_c('v-list-item',{attrs:{"data-qa":_vm.mode === 'render'
                              ? 'delete-collection-render'
                              : 'delete-collection-product'},on:{"click":function($event){return _vm.beforeDeleteCollection(collection)}}},[_c('v-list-item-icon',{staticClass:"mr-3 my-auto"},[_c('v-icon',{staticStyle:{"margin-left":"-4px"},attrs:{"color":hover ? 'red' : '',"size":"24"}},[_vm._v("mdi-trash-can")])],1),_c('v-list-item-title',{staticClass:"pl-1"},[_vm._v("Удалить")])],1)],1)]}}],null,true)})],1)],1)],1):_vm._e(),(_vm.mode === 'render' || _vm.mode === 'ai-photo' || !hover)?_c('div',{staticClass:"collections__productcount"},[_vm._v(" "+_vm._s(collection.count ? collection.count : 0)+" ")]):_vm._e()],1)])]}}],null,true)})}),1)],1)],1),_c('PopupVue',{attrs:{"width":'400'},model:{value:(_vm.isCreateCollection),callback:function ($$v) {_vm.isCreateCollection=$$v},expression:"isCreateCollection"}},[_c('CreateCollectionVue',{on:{"update-collection":function($event){return _vm.getCollections()},"close-popup":function($event){_vm.isCreateCollection = false}}})],1),_c('PopupVue',{attrs:{"width":'400'},model:{value:(_vm.isConfirmEditCollection),callback:function ($$v) {_vm.isConfirmEditCollection=$$v},expression:"isConfirmEditCollection"}},[_c('EditCollectionVue',{attrs:{"collection":_vm.collectionToEdit},on:{"close-popup":function($event){_vm.isConfirmEditCollection = false}}})],1),_c('PopupVue',{attrs:{"width":'400'},model:{value:(_vm.isConfirmDeleteCollection),callback:function ($$v) {_vm.isConfirmDeleteCollection=$$v},expression:"isConfirmDeleteCollection"}},[_c('DeleteAcceptVue',{key:_vm.collectionToDelete.id,attrs:{"name":'коллекцию',"text":`${_vm.collectionToDelete.name} будет удалена`,"value":_vm.collectionToDelete},on:{"close-popup":function($event){_vm.isConfirmDeleteCollection = false},"delete-value":function($event){return _vm.deleteCollection($event)}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }