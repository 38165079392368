<template>
  <v-card class="zarbo-delete-popup">
    <v-card-title class="zarbo-delete-popup__title pa-0 mb-2"
      >Удалить {{ name }}?</v-card-title
    >
    <v-card-text class="zarbo-delete-popup__text pa-0 mb-5">
      {{ name.charAt(0).toUpperCase() + name.slice(1) }} {{ text }} без возможности восстановления.
    </v-card-text>
    <v-card-actions class="pa-0">
      <v-btn
        class="elevation-0"
        @click="closePopup"
        color="secondary"
        height="40"
        width="153"
        >Не удалять</v-btn
      >
      <v-btn
        @click="deleteValue"
        depressed
        outlined
        class="btn__err btn__err-active ml-3"
        color="error"
        height="40"
        width="134"
        ref="buttonAcceptDelete"
        >Удалить</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "delete-accept-popup",
  props: ['name', 'text', 'value'],

  data: () => ({
  }),

  mounted () {
    this.$nextTick(() => {
      this.$refs.buttonAcceptDelete.$el.focus()
    })
  },

  methods: {
    closePopup() {
      this.$emit('close-popup')
    },
    deleteValue() {
      this.$emit('delete-value', this.value)
    }
  }
}
</script>

<style lang="scss">
  .v-btn:focus::before {
    opacity: 0 !important;
  }
</style>