import CopyIconVue from "@/components/Utils/Icons/CopyIconVue.vue";
import EditIconVue from "@/components/Utils/Icons/EditIconVue.vue";
import OpenTabIconVue from "@/components/Utils/Icons/OpenTabIconVue.vue";
import CodeIntegrationIconVue from "@/components/Utils/Icons/CodeIntegrationIconVue";
import CloseRoundedVue from "@/components/Utils/Icons/CloseRoundedVue";
import CogIconVue from "@/components/Utils/Icons/CogIconVue.vue";
import CachedIconVue from "@/components/Utils/Icons/CachedIconVue.vue";
import PaperclipIconVue from "@/components/Utils/Icons/PaperclipIconVue.vue";
import MinusIconVue from "@/components/Utils/Icons/MinusIconVue.vue";
import PlusIconVue from "@/components/Utils/Icons/PlusIconVue.vue";
import DisableIconVue from "@/components/Utils/Icons/DisableIconVue.vue";
import PlusRotateIconVue from "@/components/Utils/Icons/PlusRotateIconVue.vue";
import CreateIconProductVue from "@/components/Utils/Icons/CreateIconProductVue.vue";
import CancelIconVue from "@/components/Utils/Icons/CancelIconVue.vue";
import CheckIconVue from "@/components/Utils/Icons/CheckIconVue.vue";
import TooltipIconVue from "@/components/Utils/Icons/TooltipIconVue.vue";
import QuestionVue from "@/components/Utils/Icons/QuestionVue.vue";
import RefreshVue from "@/components/Utils/Icons/RefreshVue.vue";
import ReuploadIconVue from "@/components/Utils/Icons/ReuploadIconVue.vue";
import ConvertIconVue from "@/components/Utils/Icons/ConvertIconVue.vue";
import DownloadIconVue from "@/components/Utils/Icons/DownloadIconVue.vue";
import DelIconVue from "@/components/Utils/Icons/DelIconVue.vue";
import UploadIconVue from "@/components/Utils/Icons/UploadIconVue.vue";
import CopyLinkVue from "@/components/Utils/Icons/CopyLinkVue.vue";
import SearchIconVue from "@/components/Utils/Icons/SearchIconVue.vue";
import LockIconVue from "@/components/Utils/Icons/LockIconVue.vue";
import LockOpenIconVue from "@/components/Utils/Icons/LockOpenIconVue.vue";
import DragIconVue from "@/components/Utils/Icons/DragIconVue.vue";
import CheckboxIconVue from "@/components/Utils/Icons/CheckboxIconVue.vue";
import ZarboPreIconVue from "@/components/Utils/Icons/ZarboPreIconVue.vue";
import QrIconVue from "@/components/Utils/Icons/QrIconVue.vue";
import CheckIconSpecialForMaterialsVue from "@/components/Utils/Icons/CheckIconSpecialForMaterialsVue.vue";
import TrueCheckBoxVue from "@/components/Utils/Icons/TrueCheckBoxVue.vue";
import FalseCheckBoxVue from "@/components/Utils/Icons/FalseCheckBoxVue.vue";
import TinyPlusVue from "@/components/Utils/Icons/TinyPlusVue.vue";
import FoldersIconVue from "@/components/Utils/Icons/FoldersIconVue.vue";
import TagsIconVue from "@/components/Utils/Icons/TagsIconVue.vue";
import UserProfileVue from "@/components/Utils/Icons/UserProfileVue.vue";
import LogoutVue from "@/components/Utils/Icons/LogoutVue.vue";
import ArrowLeftIconVue from "@/components/Utils/Icons/ArrowLeftIconVue.vue";
import ExitIconVue from "@/components/Utils/Icons/ExitIconVue.vue";
import UploadModelIconVue from "@/components/Utils/Icons/UploadModelIconVue.vue";
import InstallForIconVue from "@/components/Utils/Icons/InstallForIconVue.vue";
import RenameIconVue from "@/components/Utils/Icons/RenameIconVue.vue";
import TeamsIconVue from "@/components/Utils/Icons/TeamsIconVue.vue";
import CopyProductUrlVue from "@/components/Utils/Icons/CopyProductUrlVue.vue";
import TgIconVue from "@/components/Utils/Icons/TgIconVue.vue";
import TimerVue from "@/components/Utils/Icons/TimerVue.vue";
import UniteVue from "@/components/Utils/Icons/UniteVue.vue";
import MagGlassIconVue from "@/components/Utils/Icons/MagGlassIconVue.vue";
import PhoneArIconVue from "@/components/Utils/Icons/PhoneArIconVue.vue";
import ChevronUpIconVue from "@/components/Utils/Icons/ChevronUpIconVue.vue";
import ChevronDownIconVue from "@/components/Utils/Icons/ChevronDownIconVue.vue";
import GoRoomTeamIconVue from "@/components/Utils/Icons/GoRoomTeamIconVue.vue";
import RenderIcon from "@/components/Utils/Icons/RenderIconVue.vue";
import PlayIcon from "@/components/Utils/Icons/PlayIconVue.vue";
import EmptyIcon from "@/components/Utils/Icons/EmptyIconVue.vue";
import UploadDashed from "@/components/Utils/Icons/UploadDashedVue.vue";
import ZoomPlus from "@/components/Utils/Icons/ZoomPlusVue.vue";
import AnaliticsIconVue from "@/components/Utils/Icons/AnaliticsIconVue.vue";
import DeactivatedIconVue from "@/components/Utils/Icons/DeactivatedIconVue.vue";
import ActivatedIconVue from "@/components/Utils/Icons/ActivatedIconVue.vue";
import CubeIconVue from "@/components/Utils/Icons/CubeIconVue.vue";
import YandexLogoIconVue from "@/components/Utils/Icons/YandexLogoIconVue.vue";
import VkLogoIconVue from "@/components/Utils/Icons/VkLogoIconVue.vue";
import GoogleLogoIconVue from "@/components/Utils/Icons/GoogleLogoIconVue.vue";
import TinkoffLogoIconVue from "@/components/Utils/Icons/TinkoffLogoIconVue.vue";
import SuccessIconVue from "@/components/Utils/Icons/SuccessIconVue.vue";
import ExclamationIconVue from "@/components/Utils/Icons/ExclamationIconVue.vue";
import MailIconVue from "@/components/Utils/Icons/MailIconVue.vue";
import AiPhotoIconVue from "@/components/Utils/Icons/AiPhotoIconVue.vue";
import ShareIconVue from "@/components/Utils/Icons/ShareIconVue.vue";
import ConnectionSocialIconVue from "@/components/Utils/Icons/ConnectionSocialIconVue.vue";
import AppleLogoIconVue from "@/components/Utils/Icons/AppleLogoIconVue.vue";
import HelpIconVue from "@/components/Utils/Icons/HelpIconVue.vue";
import HighlightIconVue from "@/components/Utils/Icons/HighlightIconVue.vue";
import ShacallingIconVue from "@/components/Utils/Icons/ShacallingIconVue.vue";
import dddCube from "@/components/Utils/Icons/dddCube.vue";
import BrandingIcon from "@/components/Utils/Icons/BrandingIcon.vue";
import HotspotIcon from "@/components/Utils/Icons/HotspotIcon.vue";
import AnimationIcon from "@/components/Utils/Icons/AnimationIcon.vue";
import AutoplayAnimationIconVue from '@/components/Utils/Icons/AutoplayAnimationIconVue.vue'
import ActiveDotAnimationIconeVue from '@/components/Utils/Icons/ActiveDotAnimationIconeVue.vue'
import ArrowRightIconVue from "@/components/Utils/Icons/ArrowRightIconVue.vue";
import ArrowsBothIconVue from "@/components/Utils/Icons/ArrowsBothIconVue.vue";
import PauseIconVue from '@/components/Utils/Icons/PauseIconVue.vue';
import RemoveUpdownArrowIconVue from '@/components/Utils/Icons/RemoveUpdownArrowIconVue.vue';
import SizerIcon from "@/components/Utils/Icons/SizerIconVue.vue";

const iconValues = {
  copy: { component: CopyIconVue },
  edit: { component: EditIconVue }, // карандаш. Используется материалы, продукты, теги
  openTab: { component: OpenTabIconVue },
  codeIntegration: { component: CodeIntegrationIconVue },
  closeRounded: { component: CloseRoundedVue }, // закрытие. Используется повсеместно
  cog: { component: CogIconVue }, //Шестеренка, используется в Виджет/Кнопка действия
  cached: { component: CachedIconVue }, //Круг, состоящий из двух стрелочек, используется Виджеты/Превью продукта/Кнопка загрузить если уже загружен скриншот
  paperclip: { component: PaperclipIconVue }, //Скрепка повернутая по часовой стрелке, используется Виджеты/Превью продукта/Кнопка загрузить если уже загружен скриншот
  minus: { component: MinusIconVue }, //Минус, используется Виджет/Брендирование/Минус на изменение размера иконки
  plus: { component: PlusIconVue }, //Плюс, используется Виджет/Брендирование/Плюс на изменение размера иконки
  disableIcon: { component: DisableIconVue }, //Перечеркнутый кружок справа-налево, используется Виджет/Кнопка действия/Шестеренка/Иконки по умлочанию/Откл.
  plusRotate: { component: PlusRotateIconVue }, //Перевернутый плюс, используется как кнопка закрытия всплывающих окон
  createProduct: { component: CreateIconProductVue },
  cancel: { component: CancelIconVue }, //Перечеркнутый кружок слева-направо, используется Виджет/Кнопка действия/Шестеренка/Поле ввода url
  check: { component: CheckIconVue }, //Галочка подтверждения, используется Виджет/Кнопка действия/Шестеренка/Поле ввода url
  tooltip: { component: TooltipIconVue }, //Подсказка, используется Виджет/Оптимизация
  reupload: { component: ReuploadIconVue }, //Облачко со стрелочкой по кругу, используется Модели/Формат модели/Меню/Перезалить
  convert: { component: ConvertIconVue }, //Две стрелочки, создающие овал, используется Модели/Формат модели/Меню/Сконвертировать
  download: { component: DownloadIconVue }, //Стрелчока вниз с подчеркиванием, используется Модели/Формат модели/Меню/Скачать
  del: { component: DelIconVue }, //Корзина, используется Модели/Формат модели/Меню/Удалить
  upload: { component: UploadIconVue }, //Стрелчока вверх с подчеркиванием, используется (если нет такой модели) Модели/Формат модели/Меню/Загрузить
  question: { component: QuestionVue }, // Иконка вопроса. Использовалась в материалах
  refresh: { component: RefreshVue }, // Иконка обновления или замены. Использовалась в материалах обновление канала
  copyLink: { component: CopyLinkVue }, // Иконка копирования ссылки. Использовалась в Контекстном меню, при наведении на пункты Открыть в новой вкладке и Интеграция, также в кнопке интеграции "Копировать"
  search: { component: SearchIconVue }, // Поиск материалов 3ий шаг
  lock: { component: LockIconVue }, // Замок закрытый в блокировке продукта(Интеграция)
  lockOpen: { component: LockOpenIconVue }, // Замок закрытый в блокировке продукта(Интеграция)
  checkboxIcon: { component: CheckboxIconVue }, //Иконка с нажатой синей галочкой праймари. Используется при регистрации/Согласие с офертой
  zarboPre: { component: ZarboPreIconVue }, //Иконка Зарбо с прелоадером внутри
  drag: { component: DragIconVue }, //Иконка перетягивания блока, используется в материалах
  qr: { component: QrIconVue }, //Иконка qr-кода, используется в Меню продукта
  checkIconMaterial: { component: CheckIconSpecialForMaterialsVue }, // компонент говорит сам за себя
  trueCheckBox: { component: TrueCheckBoxVue }, // Иконка для активной коллекции
  falseCheckBox: { component: FalseCheckBoxVue }, // Иконка для неактивной коллекции
  tinyPlus: { component: TinyPlusVue }, // ТОНКИЙ ПЛЮС!
  folders: { component: FoldersIconVue }, // Папки. Используется в левом меню
  tags: { component: TagsIconVue }, // Теги. Используется в левом меню
  userProfile: { component: UserProfileVue }, // Теги. Используется в левом меню
  logout: { component: LogoutVue }, // Теги. Используется в левом меню
  arrowLeft: { component: ArrowLeftIconVue }, //Стрелочка влево, используется в личном кабинете - кнопка Назад
  exit: { component: ExitIconVue }, //Кнопка выход пользователя
  uploadModel: { component: UploadModelIconVue }, //Иконка загрузки модели на 2 шаге степпера
  installFor: { component: InstallForIconVue }, //Иконка Установить для в меню 2 шага при нажатии на формат
  rename: { component: RenameIconVue }, //Иконка Переименовать в меню 2 шага при нажатии на формат
  teams: { component: TeamsIconVue }, //Иконка Команда, главная страница панель навигации слева
  copyUrl: { component: CopyProductUrlVue }, //Иконка копирования url продукта
  tgSupport: { component: TgIconVue }, //Иконка Телеграма, используется в Поддержке
  timer: { component: TimerVue }, // Иконка таймера в интеграции
  unite: { component: UniteVue },
  magGlass: { component: MagGlassIconVue }, // Иконка увеличительного стекла, используется на 4 шаге при наведении на QR-код
  phoneAr: { component: PhoneArIconVue }, //Иконка телефона для наведения, используется на 4 шаге при открытии QR-кода внизу
  chevronUp: { component: ChevronUpIconVue }, // Иконка стрелочка вверх с закруглениями, используется например в командах - Участники, Приглашения
  chevronDown: { component: ChevronDownIconVue }, // Иконка стрелочка вниз с закруглениями, используется например в командах - Участники, Приглашения
  goRoomTeam: { component: GoRoomTeamIconVue }, //Иконка перехода в кабинет команды, используется в Командах
  render: { component: RenderIcon }, //Иконка рендера, используется в навигации и таблице рендера
  play: { component: PlayIcon }, // Иконка запустить используется в меню рендера
  empty: { component: EmptyIcon }, // Иконка отсутствия hdri
  uploadDashed: { component: UploadDashed }, // Иконка используется в загрузках КО drag and drop
  zoomPlus: { component: ZoomPlus },
  analitics: { component: AnaliticsIconVue }, //Иконка Аналитика на главной странице
  deactivated: { component: DeactivatedIconVue }, //Иконка деактивации продукта
  activated: { component: ActivatedIconVue }, //Иконка активации продукта
  cube: { component: CubeIconVue }, //Иконка кубика при логине/регистрации включает рандомную модель
  yandexLogo: { component: YandexLogoIconVue }, //Логотип Яндекса на странице логина
  vkLogo: { component: VkLogoIconVue }, //Логотип ВКонтакте на странице логина
  googleLogo: { component: GoogleLogoIconVue }, //Логотип Гугл на странице логина
  tinkoffLogo: { component: TinkoffLogoIconVue }, //Логотип Тинькофф на странице логина
  success: { component: SuccessIconVue }, //Иконка успешного уведомления
  error: { component: ExclamationIconVue }, //Иконка неуспешного уведомления - восклицательный знак
  mail: { component: MailIconVue }, //Иконка почты (конверт)
  aiPhoto: { component: AiPhotoIconVue }, //Иконка AI-фото
  share: { component: ShareIconVue }, //Иконка Поделиться при наведении на сгенерированные изображения в AI-фото
  connectionSocial: { component: ConnectionSocialIconVue }, //Иконка связывания аккаунта в Профиле пользователя
  appleLogo: { component: AppleLogoIconVue }, //Логотип Apple на странице логина
  help: { component: HelpIconVue }, //Иконка помощи на главной странице внизу
  highlightShaders: { component: HighlightIconVue }, //Иконка выделения shaders, используется в материалах
  shacallingIconVue: { component: ShacallingIconVue }, //Иконка КО когда она еще не появилась,
  dddCube: { component: dddCube },
  branding: { component: BrandingIcon },
  hotspot: { component: HotspotIcon },
  animations: { component: AnimationIcon },
  autoplayAnimation: { component: AutoplayAnimationIconVue }, //Иконка запуска анимации
  activeDotAnimation: { component: ActiveDotAnimationIconeVue }, //Иконка выбранной активной анимации
  arrowRight: { component: ArrowRightIconVue }, //Иконка стрелки вправо, анимация
  arrowsBoth: { component: ArrowsBothIconVue }, ///Иконка стрелки влево и вправо, анимация
  pauseIcon: { component: PauseIconVue }, //Иконка паузы в анимациях
  removeUpdownArrow: { component: RemoveUpdownArrowIconVue}, //Иконка сброса анимации с автозапуска
  sizer: {component: SizerIcon} // Иконка увеличения/уменьшения размера окна предпросмотра виджета
};

export default iconValues;
