<template>
  <div
    class="left-menu"
    :style="{
      width: menuWidth + 'px',
      'border-right': isMenuOpen ? '1px solid #e0e0e0' : 'none',
    }"
  >
    <router-link
      tag="div"
      to="/"
      class="left-menu__logo"
      :style="{ width: isMenuOpen ? '88px' : '58px' }"
    >
      <img
        :src="
          isMenuOpen
            ? require('@/assets/img/zarbo-logo.png')
            : require('@/assets/img/zarbo-logo-ar.svg')
        "
        alt=""
      />
    </router-link>
    <v-tabs
      v-model="selectedTab"
      vertical
      centered
      optional
      icons-and-text
      hide-slider
      background-color="primary"
      class="left-menu__tabs tab-teams"
      active-class="left-menu__active-tab"
    >
      <div>
        <v-tab @click="closeTeam" :style="tabStyle">
          {{ isMenuOpen ? "Коллекции" : "" }}
          <v-icon :size="isMenuOpen ? '38px' : '26px'"
            >$vuetify.icons.folders</v-icon
          >
        </v-tab>
        <v-tab @click="closeTeam" :style="tabStyle">
          {{ isMenuOpen ? "Теги" : "" }}
          <v-icon :size="isMenuOpen ? '38px' : '26px'"
            >$vuetify.icons.tags</v-icon
          >
        </v-tab>
        <PermissionWrapperVue
          :billingValue="-2"
          :billingCode="'render360'"
          :textStyles="{ paddingTop: '14px', paddingBottom: '14px' }"
          :iconStyles="{ right: '4px' }"
        >
          <v-tab @click="openRender" :style="tabStyle">
            {{ isMenuOpen ? "Рендеры" : "" }}
            <v-icon :size="isMenuOpen ? '38px' : '26px'"
              >$vuetify.icons.render</v-icon
            >
          </v-tab>
        </PermissionWrapperVue>
        <PermissionWrapperVue
          :billingCode="'ai_gen'"
          :billingValue="-2"
          :permissionCode="'read_ai_generator'"
          :textStyles="{ paddingTop: '14px', paddingBottom: '14x' }"
          :iconStyles="{ right: '4px' }"
        >
          <v-tab @click="openAiPhoto" :style="tabStyle">
            {{ isMenuOpen ? "AI-фото" : "" }}
            <v-icon :size="isMenuOpen ? '38px' : '26px'"
              >$vuetify.icons.aiPhoto</v-icon
            >
          </v-tab>
        </PermissionWrapperVue>
      </div>

      <div class="justify-space-between">
        <PermissionWrapperVue
          :billingCode="'access_analytic'"
          :textStyles="{ paddingTop: '14px', paddingBottom: '14px' }"
          :iconStyles="{ right: '-2px' }"
          style="
            width: 76px;
            height: 77px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 8px;
          "
        >
          <v-btn
            link
            target="_blank"
            :href="selectedTeam?.team?.public_url"
            class="left-menu__tabs-analitics"
            :style="tabStyle"
          >
            Аналитика
            <v-icon color="#7B7AA8" size="38px"
              >$vuetify.icons.analitics</v-icon
            >
          </v-btn>
        </PermissionWrapperVue>

        <v-tab :style="tabStyle" class="mb-4">
          {{ isMenuOpen ? "Команды" : "" }}
          <v-icon :size="isMenuOpen ? '38px' : '26px'"
            >$vuetify.icons.teams</v-icon
          >
        </v-tab>

        <v-menu
          offset-x
          v-model="isUserMenuOpen"
          content-class="mini-user-info"
          :nudge-right="isMenuOpen ? 10 : -20"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-tabs
              vertical
              optional
              icons-and-text
              hide-slider
              class="tabs-lk left-menu__tabs"
              :class="isMenuOpen ? '' : 'tabs-lk-mini'"
              background-color="transparent"
            >
              <v-tab
                v-bind="attrs"
                v-on="on"
                :style="tabStyle"
                :class="
                  selectedTab === 5
                    ? 'left-menu__active-tab_profile v-tab--active'
                    : ''
                "
                data-qa="profile-button"
              >
                {{ isMenuOpen ? "Профиль" : "" }}
                <v-icon v-if="!user.avatar" :size="isMenuOpen ? '38px' : '26px'"
                  >$vuetify.icons.userProfile</v-icon
                >
                <div
                  v-else
                  :style="{ 'background-image': 'url(' + user.avatar + ')' }"
                  class="left-menu__user-avatar"
                  :class="selectedTab === 5 ? 'v-icon' : ''"
                ></div>
              </v-tab>
              <v-divider
                :class="
                  isMenuOpen ? 'left-menu__divider' : 'left-menu__divider-mini'
                "
              ></v-divider>
            </v-tabs>
          </template>
          <div>
            <!-- email -->
            <div class="mini-user-info__username pr-5">
              <p class="text-truncate">{{ user.email }}</p>
            </div>
            <!-- кнопки -->
            <div class="mini-user-info__buttons">
              <v-btn
                text
                :rounded="false"
                @click="goToLk"
                data-qa="profile-menu-profile"
                ><v-icon color="secondary" size="20" class="mr-1"
                  >mdi-account</v-icon
                >Открыть профиль</v-btn
              >
              <v-btn
                text
                :rounded="false"
                @click="logout"
                data-qa="profile-menu-login-logout"
                ><v-icon color="secondary" size="20" class="mr-1"
                  >$vuetify.icons.logout</v-icon
                >Выйти</v-btn
              >
            </div>
            <!-- подписка -->
            <div class="mini-user-info__plan">
              <div class="mini-user-info__plan-status">
                <v-img
                  width="16"
                  contain
                  max-height="16"
                  :src="require('@/assets/img/mini-logo-plan.svg')"
                />
                <p>{{ statusText }}</p>
              </div>
              <div class="mini-user-info__plan-subscribe-name mt-2">
                <p class="text-truncate">
                  Тарифный план:
                  {{
                    subscription && subscription.plan
                      ? subscription.plan.name
                      : ""
                  }}
                </p>
              </div>
              <div class="mini-user-info__plan-next-payment mt-2">
                <p class="text-truncate">
                  След. платёж:
                  {{
                    subscription && subscription.next_payment_date
                      ? formatDate(subscription.next_payment_date)
                      : ""
                  }}
                </p>
              </div>
            </div>
            <div class="mini-user-info__support mt-2 mx-4" @click="copyEmail">
              <p>Техническая поддержка: {{ this.$config.EMAIL_SUPPORT }}</p>
            </div>
          </div>
        </v-menu>

        <!-- <v-divider></v-divider> -->

        <v-menu
          offset-x
          v-model="isHelpMenuOpen"
          content-class="mini-user-info user-support"
          nudge-right="14"
          nudge-bottom="10"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-tabs
              vertical
              optional
              icons-and-text
              hide-slider
              background-color="transparent"
              v-model="isOpenSupport"
            >
              <v-badge
                :value="isNewVideoOnYoutubePlaylist"
                offset-x="18"
                offset-y="26"
                color="#E85A63"
                dot
              >
                <v-tab
                  style="margin-top: 12px"
                  class="user-support__help"
                  active-class="user-support__help-active"
                  :style="tabStyle"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ isMenuOpen ? "Помощь" : "" }}
                  <v-icon :size="isMenuOpen ? '34px' : '26px'"
                    >$vuetify.icons.help</v-icon
                  >
                </v-tab>
              </v-badge>
            </v-tabs>
          </template>
          <div>
            <div class="mini-user-info__buttons">
              <v-btn
                link
                target="_blank"
                href="https://t.me/zarbotech_support"
                @click="isOpenSupport = null"
                text
                :rounded="false"
                class="user-support-text user-support-text__tg"
                ><v-icon color="secondary" size="18" class="mr-2"
                  >$vuetify.icons.tgSupport</v-icon
                >Поддержка в TG</v-btn
              >
              <div class="d-flex align-center user-support-text">
                <v-btn
                  text
                  :rounded="false"
                  @click="openYoutubePlaylist"
                  class="user-support-text"
                  ><v-icon color="secondary" size="18" class="mr-2"
                    >$vuetify.icons.play</v-icon
                  >Видеоуроки</v-btn
                >
                <div
                  v-if="isNewVideoOnYoutubePlaylist"
                  class="user-support__new"
                >
                  NEW
                </div>
              </div>
            </div>
          </div>
        </v-menu>
      </div>

      <v-tab-item :eager="true">
        <CollectionsVue :key="routePath" />
      </v-tab-item>
      <v-tab-item :eager="true">
        <TagsVue />
      </v-tab-item>
      <v-tab-item :eager="true">
        <CollectionsVue :key="routePath" />
      </v-tab-item>
      <v-tab-item :eager="true">
        <CollectionsVue :key="routePath" />
      </v-tab-item>
      <v-tab-item :eager="true">
        <TeamsVue />
      </v-tab-item>
    </v-tabs>

    <div
      v-if="this.selectedTeam.team && isMenuOpen && !isUserPage && !isTeamPage"
      class="teams__owner-wrapper d-flex align-center py-2 pl-5"
    >
      <v-icon v-if="!this.selectedTeam.team.owner.avatar" size="24"
        >$vuetify.icons.userProfile</v-icon
      >
      <div
        v-else
        :style="{
          'background-image':
            'url(' + this.selectedTeam.team.owner.avatar + ')',
        }"
        class="teams__owner-avatar"
      ></div>
      <p class="teams__owner ml-1">{{ this.selectedTeam.team.name }}</p>
    </div>
    <!-- иконка пользователя -->
    <!-- А че коммент под блоком? -->
    <PopupVue v-model="isOpenPopupYoutube" :width="'50%'">
      <iframe
        width="850"
        height="600"
        :src="`https://www.youtube.com/embed/videoseries?si=crZhTdVyPg8Fgz20&amp;list=${$config.YOUTUBE_PLAYLIST_ID}`"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
    </PopupVue>
  </div>
</template>

<script>
import CollectionsVue from "./CollectionsVue.vue";
import TagsVue from "./TagsVue.vue";
import TeamsVue from "./TeamsVue.vue";
import PermissionWrapperVue from "../Utils/PermissionWrapperVue.vue";
import PopupVue from "@/components/Utils/PopupVue.vue";
import jwt_decode from "jwt-decode";
import { mapState } from "vuex";

export default {
  name: "left-menu",
  components: {
    CollectionsVue,
    TagsVue,
    TeamsVue,
    PermissionWrapperVue,
    PopupVue,
    // GenerateAiPhoto
  },

  data: () => ({
    selectedTab: 0,
    isUserMenuOpen: false,
    lastSelectedTab: undefined,
    isHelpMenuOpen: false,
    isOpenPopupYoutube: false,
    isOpenSupport: null,
  }),

  computed: {
    ...mapState({
      user: (state) => state.appStore.user,
      subscription: (state) => state.appStore.subscription,
      selectedTeam: (state) => state.appStore.selectedTeam,
      lastLocation: (state) => state.appStore.lastLocation,
      selectedTabLast: (state) => state.appStore.selectedTabLast,
      isNewVideoOnYoutubePlaylist: (state) =>
        state.appStore.isNewVideoOnYoutubePlaylist,
    }),
    statusText() {
      if (!this?.subscription?.status) {
        return "";
      }
      const status = this.subscription.status;
      switch (status) {
        case "new":
          return "Ожидание оплаты";
        case "is_active":
          return "Активна";
        case "past_due":
          return "Оплата просрочена";
        case "canceled":
          return "Отменена";
        default:
          return "Бесплатная";
      }
    },
    isTeamPage() {
      return this.$route.path === "/teams";
    },
    isUserPage() {
      return this.$route.path === "/user";
    },
    isMenuOpen() {
      return true;
    },
    menuWidth() {
      if (this.isMenuOpen) {
        if ((this.isUserPage && this.selectedTab === 5) || this.isTeamPage) {
          return 88;
        } else {
          return 388;
        }
      } else {
        return 58;
      }
    },
    tabStyle() {
      return this.isMenuOpen
        ? {
            width: "76px",
            height: "77px",
            borderRadius: "10px",
            marginBottom: "8px",
          }
        : {
            width: "50px",
            height: "50px",
            padding: "0px 3px",
            margin: "0",
            marginBottom: "4px",
            borderRadius: "10px",
          };
    },
    routePath() {
      return this.$route.path;
    },
  },

  watch: {
    isHelpMenuOpen(newVal) {
      if (!newVal) {
        this.isOpenSupport = undefined;
      }
    },
    selectedTab(newVal, oldVal) {
      this.lastSelectedTab = oldVal;
      localStorage.setItem("selectedTab", newVal);

      if (newVal !== undefined) {
        this.$store.dispatch("SET_VALUE", {
          key: "lastSelectedTab",
          value: newVal,
        });
      }
    },
    menuWidth: {
      handler(newVal) {
        this.$emit("change-menu-width", newVal);
      },
      immediate: true,
    },
  },

  created() {
    let _selectedTab = localStorage.getItem("selectedTab");

    this.selectedTab =
      _selectedTab === "undefined" ? undefined : Number(_selectedTab);

    if (
      this.$router.currentRoute.path !== "/" &&
      (this.selectedTab === 0 || this.selectedTab === 1)
    ) {
      this.$router.push("/").catch(() => {});
    }

    if (
      this.$router.currentRoute.path !== "/render" &&
      this.selectedTab === 2
    ) {
      this.$router.push("/render").catch(() => {});
    }

    if (
      this.$router.currentRoute.path !== "/render" &&
      this.selectedTab === 3
    ) {
      this.$router.push("/ai-photo").catch(() => {});
    }

    if (this.$router.currentRoute.path !== "/teams" && this.selectedTab === 4) {
      this.$router.push("/teams").catch(() => {});
    }

    let token = localStorage.getItem("zarboAccessToken");

    if (token) {
      let decoded = jwt_decode(token);

      this.getUserInfoMix(decoded.user_id);
    }
  },

  methods: {
    openYoutubePlaylist() {
      this.isOpenPopupYoutube = true;
      this.isOpenSupport = null;

      let lastOpenYoutubePlaylist = new Date().toISOString();

      localStorage.setItem("lastOpenYoutubePlaylist", lastOpenYoutubePlaylist);

      this.$store.dispatch("SET_VALUE", {
        key: "isNewVideoOnYoutubePlaylist",
        value: false,
      });
    },

    openAiPhoto() {
      if (this.$router.currentRoute.path !== "/ai-photo") {
        this.$router.push("/ai-photo");
      }
    },

    openRender() {
      if (this.$router.currentRoute.path !== "/render") {
        this.$router.push("/render");
      }
    },
    closeTeam() {
      if (this.$router.currentRoute.path !== "/") {
        this.$router.push("/");
      }
    },

    logout() {
      localStorage.removeItem("zarboAccessToken");
      localStorage.removeItem("zarboRefreshToken");
      localStorage.removeItem("selectedTeam");
      localStorage.removeItem("selectedTab");
      localStorage.removeItem("zarbo-selected-collection");
      localStorage.removeItem("lastOpenYoutubePlaylist");
      localStorage.removeItem("oauthConnected");

      this.$store.dispatch("SET_VALUE", {
        key: "selectedCollection",
        value: null,
      });
      this.$store.dispatch("SET_VALUE", {
        key: "selectedTeam",
        value: {},
      });
      this.$store.dispatch("SET_VALUE", {
        key: "collections",
        value: [],
      });
      this.isUserMenuOpen = false;
      this.isHelpMenuOpen = false;
      this.$store.dispatch("SET_VALUE", { key: "subscription", value: null });
      this.$store.dispatch("SET_VALUE", { key: "isAuthorized", value: false });
      this.$store.dispatch("SET_VALUE", { key: "allTeams", value: [] });
      this.$store.dispatch("SET_VALUE", { key: "products", value: [] });
      this.$router.push("/login");
    },

    async goToLk() {
      this.selectedTab = 5;
      this.isUserMenuOpen = false;
      if (this.$route.name !== "user") {
        // я хз почему, но бывает, что с первого раза не редиректит
        this.$router.push("/user");
      }
    },

    formatDate(dateString) {
      if (!dateString) return "";
      let date = new Date(dateString);
      return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
    },

    copyEmail() {
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(this.$config.EMAIL_SUPPORT)
          .then(() => {
            this.$notify({
              group: "foo",
              text:
                "Адрес " + this.$config.EMAIL_SUPPORT + " успешно скопирован",
              type: "success",
            });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        console.log("нет navigator.clipboard");
      }
    },
  },
};
</script>

<style lang="scss">
.tab-teams__closed {
  .v-tab:first-child {
    margin-bottom: 4px !important;
  }
}

.left-menu {
  .v-tabs--vertical > .v-tabs-bar .v-tabs-bar__content {
    align-items: center !important;
    justify-content: space-between;
  }
  height: 100%;
  overflow: hidden;
  flex: none;
  transition: all 0.3s;

  .v-tabs--icons-and-text > .v-tabs-bar .v-tab > *:first-child {
    margin-bottom: 0 !important;
  }

  .theme--light.v-tabs .v-tab:hover::before {
    border-radius: 10px;
  }

  &__logo {
    background-color: #4e4087;
    height: 67px;
    height: 57px;
    cursor: pointer;

    img {
      height: 67px;
      width: 100%;
      object-fit: contain;
      padding: 0 6px;
      // NY style
      height: 29px;
      padding-top: 0;
      margin-top: 12px;
    }
  }

  .theme--light.v-tabs .v-tab:focus::before {
    border-radius: 10px !important;
  }

  .left-menu__tabs {
    background-color: #4e4087;
    transition: all 0.3s;
    margin-bottom: -6px;
    height: calc(100% - 67px);
    height: calc(100% - 57px);
    .v-slide-group__wrapper {
      overflow-y: auto;

      .user-support__help-active {
        color: var(--v-primary-base) !important;
      }
    }

    .v-tab {
      min-width: 55px;
      margin: 0 6px 6px 6px;
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
      text-transform: capitalize;
      letter-spacing: 0px;
      color: #c7c6e3 !important;
      background-color: #372b68;
      &:not(.v-tab--active) > .v-icon {
        color: #7b7aa8 !important;
      }
      &:hover {
        &.user-support__help {
          background-color: #dddcff !important;
        }
        background-color: #dddcff;
        color: #4e4087 !important;
        &:not(.v-tab--active) > .v-icon {
          color: #4e4087 !important;
        }
      }
    }

    .left-menu__active-tab_profile:first-child {
      color: var(--v-primary-base) !important;
      background-color: #ffffff;

      .v-icon {
        color: var(--v-primary-base);
      }

      .v-icon:before {
        // белый блок на фоне картинки
        content: "";
        display: block;
        position: absolute;
        width: 76px;
        height: 75px;
        background-color: white;
        border-radius: 10px;
        left: -19px;
        bottom: -31px;
        z-index: -1;
      }
    }

    .left-menu__active-tab_profile:first-child:after {
      // белая фигня между белым квадратом и коллекциями\тегами
      content: "";
      display: block;
      position: absolute;
      right: -10px;
      width: 10px;
      height: 10px;
      background-image: url("@/assets/img/menu-active-item.svg");
    }

    .left-menu__active-tab {
      color: var(--v-primary-base) !important;
      background-color: #ffffff;
      pointer-events: none;

      .v-icon {
        color: var(--v-primary-base);
      }

      .v-icon::before {
        // белый блок на фоне картинки
        content: "";
        display: block;
        position: absolute;
        width: 76px;
        height: 75px;
        background-color: white;
        border-radius: 10px;
        left: -19px;
        bottom: -31px;
        z-index: -1;
      }
    }

    .left-menu__active-tab::after {
      // белая фигня между белым квадратом и коллекциями\тегами
      content: "";
      display: block;
      position: absolute;
      right: -10px;
      width: 10px;
      height: 10px;
      background-image: url("@/assets/img/menu-active-item.svg");
    }

    .v-window__container {
      height: 100%;

      .v-window-item {
        height: 100%;
      }
    }

    .v-window.v-item-group.theme--light.v-tabs-items {
      width: 300px;
      position: relative;
      margin-top: -67px;
      height: calc(100% + 67px);
    }

    &-analitics {
      background-color: #372b68 !important;
      &:hover {
        background-color: #dddcff !important;
        .v-btn__content {
          color: #4e4087;
        }
        .custom-icon {
          fill: #4e4087;
        }
      }
      .v-btn__content {
        display: flex;
        flex-flow: column-reverse;
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
        text-transform: capitalize;
        letter-spacing: 0px;
        color: #c7c6e3;
      }
    }
  }

  .tabs-lk-mini {
    max-height: 115px !important;
  }

  .tabs-lk {
    position: relative;
    width: 88px;
    max-width: 88px;
    transition: all 0.2s;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    max-height: 180px;
    height: 77px;

    .v-tab:last-child {
      background: none;
    }
  }

  &__divider {
    background: #8786a8;
    width: 76px;
    margin-left: 6px;

    &-mini {
      background: #8786a8;
      width: 46px !important;
      margin-left: 4px;
      margin-top: 4px;
    }
  }

  &__user-avatar {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    border-radius: 50%;
    width: 38px;
    height: 38px;
  }

  .rotate {
    transform: rotate(180deg);
  }

  &__collapse {
    cursor: pointer;
    position: absolute;
    bottom: calc(50% - 35px);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    width: 20px;
    background-image: url("@/assets/img/menu-collapse.svg");
    transition: all 0.1s;
  }
}

.mini-user-info {
  width: 234px;
  max-height: 300px;
  border: none;
  background-color: var(--v-primary-base);
  border-radius: 10px;
  box-shadow: none;

  &__username {
    width: 100%;
    padding: 11px 0 13px 24px;
    border-bottom: 1px solid #8786a8;

    p {
      color: #ffffff;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
    }
  }

  &__buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 4px;
    padding-bottom: 4px;

    .v-btn__content {
      padding-left: 5px;
      color: white !important;
      justify-content: flex-start;
    }
  }

  &__plan {
    background-color: #232451;
    border-radius: 5px;
    margin: 16px 8px 0;
    padding: 16px;

    &-status {
      display: flex;

      .v-image {
        flex: none;
        margin-top: 2px;
      }

      p {
        color: #ffffff;
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        padding-left: 8px;
      }
    }

    &-subscribe-name p {
      color: var(--v-secondary-base);
      font-size: 15px;
      line-height: 18px;
    }

    &-next-payment p {
      color: var(--v-secondary-base);
      font-size: 15px;
      line-height: 18px;
    }
  }

  &__support p {
    cursor: pointer;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: white;
    padding-bottom: 11px;
  }
}

.menu-user {
  .v-btn__content {
    display: flex;
    flex-direction: column;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    text-transform: capitalize;
    letter-spacing: 0px;
    color: #c7c6e3 !important;
  }
}
.user-support {
  width: 191px;
  // height: 76px;
  &-text {
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 22px !important;
    letter-spacing: normal;
    padding-right: 0 !important;
    border-radius: 0 0 10px 10px !important;
    padding-right: 0 !important;
    .v-btn__content {
      padding-left: 0;
      text-transform: none;
    }
    .v-btn {
      padding-right: 0 !important;
    }
    &__tg:hover {
      border-radius: 10px 10px 0 0 !important;
    }
  }
  &-text:hover {
    background-color: #232451;
  }
  &__help {
    background-color: #4e4087 !important;
    svg {
      margin-bottom: 8px;
    }
    &-active {
      background-color: #dddcff !important;
      svg {
        color: #4e4087;
      }
    }
  }
  &__new {
    border-radius: 4px;
    background: #e85a63;
    width: 34px;
    padding: 3px 4px 2px 4px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 8px;
    color: white;
  }
}
</style>
